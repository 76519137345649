import { ReactElement } from 'react';

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import dynamic from 'next/dynamic';
import Head from 'next/head';

import { BaseLayout } from '@/components/layout/layout.style';
import { NextPageWithLayout } from './_app';

const Custom404PageComponent = dynamic(() => import('../components/404/custom-404.component'), {
  ssr: false
});

const Custom404Page: NextPageWithLayout = () => <Custom404PageComponent />;

Custom404Page.getLayout = function getLayout(page: ReactElement) {
  const description = '404 - Seite nicht gefunden';
  return (
    <>
      <Head>
        <meta name='robots' content='INDEX,FOLLOW' />
        <title>ARTHY - 404</title>
        {/* @ts-ignore */}
        <meta name='description' content={description} />
        {/* @ts-ignore */}
        <meta property='og:description' content={description} />
      </Head>
      <BaseLayout>{page}</BaseLayout>
    </>
  );
};

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common', 'footer', 'api-errors']))
  }
});

export default Custom404Page;
